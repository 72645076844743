import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/dashboard/sales/index')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/account/register'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },
      {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home page instead
              next({ name: 'home' })
            } else {
              // Continue to the login page
              next()
            }
          },
        },
      },

    //Brokerage route
    {
        path: '/brokerage/office/grid',
        name: 'brokerage-office-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/office/grid')
    },

    {
        path: '/brokerage/office/list',
        name: 'brokerage-office-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/office/list')
    },
    {
        path: '/brokerage/office/edit',
        name: 'brokerage-office-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/office/office_edit')
    },



    {
        path: '/brokerage/team/grid',
        name: 'brokerage-team-grid',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/team/grid')
    },

    {
        path: '/brokerage/team/edit',
        name: 'brokerage-team-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/team/team_edit')
    },

    {
        path: '/brokerage/staff/grid',
        name: 'brokerage-staff-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/staff/grid')
    },
    {
        path: '/brokerage/staff/edit',
        name: 'brokerage-staff-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/staff/staff_edit')
    },
     
    {
        path: '/brokerage/team',
        name: 'brokerage-team',
        meta: {
            authRequired: true,
        }, 
        component: () => import('../views/pages/company_pages/brokerage/office/grid')
    },
    {
        path: '/brokerage/agent',
        name: 'brokerage-agent',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/agent/list')
    },
    {
        path: '/brokerage/agent/edit',
        name: 'brokerage-agent-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_edit')
    },
    {
        path: '/brokerage/agent/view',
        name: 'brokerage-agent-view',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_view')
    },
   
    {
        path: '/brokerage/agent/termination',
        name: 'brokerage-agent-termination',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/brokerage/agent/agent_termination')
    },
   
    {
        path: '/brokerage/documents/docs',
        name: 'brokerage-docs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/brokerage/documents/docs')
    },


    {
        path: '/brokerage/signature/online_sign',
        name: 'brokerage-signature-online-sign',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/signature/doc_online_sign')
    },


    {
        path: '/listing/pending_list',
        name: 'listing-pending',
        meta: {
            authRequired: true,
        }, 
        component: () => import('../views/pages/company_pages/listing/pending_list')
    },
    {
        path: '/listing/active_list',
        name: 'listing-active',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/active_list')
    },
    {
        path: '/listing/exclusive_list',
        name: 'listing-exclusive',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/exclusive_list')
    },

    {
        path: '/listing/overview',
        name: 'listing-overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/listing/overview')
    },


    ///////////////////////Deal START
    {
        path: '/deal/pending_deal',
        name: 'deal-pending-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/pending_deal')
    },

    {
        path: '/deal/sold_deal',
        name: 'deal-sold',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/sold_deal')
    },

    {
        path: '/deal/collapsed_deal',
        name: 'deal-collapsed',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/collapsed_deal')
    },

    {
        path: '/deal/my_handled_deal',
        name: 'deal-my-handled',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/my_handled_deal')
    },


    {
        path: '/deal/deal_overview',
        name: 'deal-overview',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/overview')
    },
   
    {
        path: '/deal/setup_new_deal',
        name: 'deal-setup',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/setup_new_deal')
    },

    {
        path: '/deal/deal_edit',
        name: 'deal-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/deal/edit_deal')
    },
   
     ///////////////////////Deal FINISH


    ///////////////////////PM ---START
    {
        path: '/pm/property_list',
        name: 'pm-property-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/property_list')
    },

    {
        path: '/pm/setup_new_pm',
        name: 'pm-setup-new-pm',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/setup_new_pm')
    },

    {
        path: '/pm/setup_new_rta',
        name: 'pm-setup-new-rta',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/setup_new_rta')
    },

    {
        path: '/pm/lanlord_list',
        name: 'pm-landlord-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/landlord_list')
    },

    {
        path: '/pm/supplier_list',
        name: 'pm-supplier-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/supplier_list')
    },
    {
        path: '/pm/overview',
        name: 'pm-overview',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/overview')
    },
    {
        path: '/pm/batch_rent_deposit_cheque',
        name: 'pm-rent-deposit-cheque',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_cheque')
    },

    {
        path: '/pm/batch_rent_deposit_etransfer',
        name: 'pm-rent-deposit-etransfer',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_etransfer')
    },

    {
        path: '/pm/batch_rent_deposit_pad',
        name: 'pm-rent-deposit-pad',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_rent_deposit_pad')
    },

    {
        path: '/pm/batch_expense',
        name: 'pm-batch-expense',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_expense')
    },

  
   
    {
        path: '/pm/add_landlord_property',
        name: 'pm-add-landlord-property',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/add_landlord_property')
    },
    {
        path: '/pm/add_landlord',
        name: 'pm-add-landlord',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/add_landlord')
    },
   
    {
        path: '/pm/property_batch_pm_ap',
        name: 'pm-property-batch-pm-ap',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/batch_pm_ap')
    },

    {
        path: '/pm/property_ap_payment',
        name: 'pm-property-ap-payment',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/rta_payment')
    },

    {
        path: '/pm/process_agent_post',
        name: 'pm-process-agent-post',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/process_agent_post')
    },

    {
        path: '/pm/property_statement_prepare',
        name: 'pm-property-statement-prepare',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/statement_prepare')
    },

    {
        path: '/pm/property_deposit_release',
        name: 'pm-property-deposit-release',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/property_deposit_release')
    },

    {
        path: '/pm/pma_list',
        name: 'pm-pma-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/pm/pma_list')
    },

    {
        path: '/pm/agent_pm_termination',
        name: 'pm-agent-pm-termination',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/pm/agent_pm_termination')
    },

   
    
     ///////////////////////PM ----FINISH

    ///////////////////////AR ----START

    {
        path: '/ar/general',
        name: 'ar-general',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/general')
    },

    {
        path: '/ar/agent_bill',
        name: 'ar-agent-bill',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/agent_bills')
    },
    {
        path: '/ar/agent_bill_detail',
        name: 'ar-agent-bill-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/agent_bill_detail')
    },
    {
        path: '/ar/charge_agent_fee',
        name: 'ar-charge-agent-fee',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/charge_agent_fee')
    },

    {
        path: '/ar/batch_charge_agents',
        name: 'ar-batch-charge-agents',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/batch_charge_agent')
    },

    {
        path: '/ar/deposit_cheque',
        name: 'ar-deposit-cheque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/deposit_cheque')
    },

    {
        path: '/ar/pad_list',
        name: 'ar-pad-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/pad_list')
    },

    {
        path: '/ap/pad_ompletion',
        name: 'pad-completion',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ar/pad_completion')
    },


    
     ///////////////////////AR ----FINISH


    ///////////////////////AP ----START
    {
        path: '/ap/deal_trust',
        name: 'ap-deal-trust',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/deal_trust')
    },
    {
        path: '/ap/pm_payment_list',
        name: 'ap-pm-payment-list',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/ap/pm_payment_list')
    },
    {
        path: '/ap/create_expense',
        name: 'ap-create-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_expense')
    },
    {
        path: '/ap/edit_expense',
        name: 'ap-edit-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/edit_expense')
    },
  

    {
        path: '/ap/create_rebgv_expense',
        name: 'ap-create-rebgv-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_rebgv_expense')
    },

    {
        path: '/ap/create_western_expense',
        name: 'ap-create-western-expnese',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_western_expense')
    },

    

    {
        path: '/ap/eft_list',
        name: 'ap-eft-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/eft_list')
    },
    {
        path: '/ap/eft_ompletion',
        name: 'eft-completion',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/eft_completion')
    },

    
    {
        path: '/ap/trust',
        name: 'ap-trust',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/trust')
    },

    {
        path: '/ap/commission',
        name: 'ap-commission',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/commission')
    },

    {
        path: '/ap/general',
        name: 'ap-general',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/general')
    },
    {
        path: '/ap/advance',
        name: 'ap-advance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/advance')
    },
    {
        path: '/ap/agent_credit',
        name: 'ap-agent-credit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/agent_credit')
    },

    {
        path: '/ap/create_recoverable_expense',
        name: 'ap-create-recoverable-expense',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/create_recoverable_expense')
    },
    
    {
        path: '/ap/tax_payment',
        name: 'ap-tax-payment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/ap/tax_payment')
    },
     ///////////////////////AP ----FINISH





    ///////////////////////Bank & Cash ----START
     {
        path: '/bt/records',
        name: 'bt-records',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/transfer_records')
    },

    {
        path: '/br/records',
        name: 'br-records',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/reconciliation_records')
    },

    {
        path: '/br/review',
        name: 'br-review',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/reconciliation_review')
    },

    {
        path: '/br/transactions',
        name: 'br-transactions',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/bank/transactions')
    },
     ///////////////////////Bank  & Cash ----FINISH
     



    //////////////////////Training ----START
    {
        path: '/events/training',
        name: 'events-training',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/events/training/index')
    },
    {
        path: '/events/training_edit',
        name: 'events-training-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/events/training/training_edit')
    },
     ///////////////////////Training ----FINISH


    //////////////////////Activity ----START
    {
        path: '/events/activity',
        name: 'events-activity',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/events/activity/index')
    },
    {
        path: '/events/activity_edit',
        name: 'events-activity-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/events/activity/activity_edit')
    },
     ///////////////////////Activity ----FINISH



      //////////////////////House ----START
    {
        path: '/house/list',
        name: 'house-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/houses/list')
    },
    {
        path: '/house/house_edit',
        name: 'house-house-edit',
        meta: {
            authRequired: true,
        },
        props: route => ({ ...route.params }),
        component: () => import('../views/pages/company_pages/houses/house_edit')
    },

     ///////////////////////House ----FINISH

     


    ///////////////////////ACCOUNTING ----START
    {
        path: '/accounting/gl_entry',
        name: 'accounting-gl-entry',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/gl_entry_list')
    },

    {
        path: '/accounting/gl_ref_query',
        name: 'accounting-gl-ref-query',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/gl_ref_query')
    },

    {
        path: '/accounting/financial_statement',
        name: 'accounting-financial-statement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/financial_statement')
    },

    {
        path: '/accounting/gl',
        name: 'accounting-gl',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/fs_gl_list')
    },

    {
        path: '/accounting/fs',
        name: 'accounting-fs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/fs_line_list')
    },

    {
        path: '/accounting/tax/agent_gst',
        name: 'accounting-tax-agent-gst',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/agent_tax')
    },
    
    {
        path: '/accounting/tax/agent_t4a',
        name: 'accounting-tax-agent-t4a',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/agent_t4a')
    },

    {
        path: '/accounting/tax/nr4_list',
        name: 'accounting-tax-nr4',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/nr4_list')
    },

    {
        path: '/accounting/positive_pay',
        name: 'accounting-positive-pay',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/accounting/positive_pay')
    },

    
    

    ///////////////////////ACCOUNTING ----FINISH


    ///////////////////////Setting ----START
    {
        path: '/setting/brokerage',
        name: 'setting-brokerage',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/brokerage')
    },

    {
        path: '/setting/bank_account',
        name: 'setting-bank_account-listing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/bank_account_list')
    },



    {
        path: '/setting/cheque',
        name: 'setting-cheque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/cheque_list')
    },


    {
        path: '/setting/printer',
        name: 'setting-printer',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/company_pages/setting/printer_list')
    },



    
     ///////////////////////Setting ----FINISH
     
]   
